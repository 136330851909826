<template><router-view /></template>
<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import { useErrorNotify } from './util';
export default defineComponent({
  setup () {
    const errorNotify = useErrorNotify()
    const store = useStore();
    const errorResponse = computed(() => store.state.errorResponse);
    watch(errorResponse, error => {
      if (error) errorNotify({ ...new Error(), name: error.response.data.detail })
    });
    return {}
  },
})
</script>
