import { yakuman } from '@/assets/options'

export class GameField {
  public static createFromJson(json: any): GameField {
    const yakumanList: { label: string; value: number }[] = (json.yakuman ?? []).map(
      (value: number) => yakuman.find(item => item.value === value) ?? { label: '', value: 0 },
    )
    return new GameField(
      json.id ?? null,
      json.memberId ?? null,
      json.field ?? null,
      json.score ?? null,
      json.position ?? null,
      json.yakuman ?? [],
      yakumanList,
      json.yakitori ?? false,
      json.ranking ?? null,
      json.game?.id,
      json.game?.title,
      new Date(json.game?.eventAt),
      json.game?.isFinished,
    )
  }

  constructor(
    public id: number | null,
    public memberId: string,
    public field: number,
    public score: number | null,
    public position: string | null,
    public yakuman: number[],
    public yakumanList: { label: string; value: number }[],
    public yakitori: boolean,
    public ranking: number,
    public gameId?: string,
    public gameTitle?: string,
    public eventAt?: Date,
    public isFinished?: boolean,
  ) {}
}

export type CreateGameFieldInput = {
  communityId: string
  gameId: string
  memberId: string
  field: number
  score: number | null
  position: string | null
  yakitori: boolean
  yakuman: number[]
}

export type UpdateGameFieldInput = {
  score: number | null
  position: string | null
  yakitori: boolean
  yakuman: number[]
}
