export const yesno = [
  { label: 'はい', value: true },
  { label: 'いいえ', value: false },
]

export const gender = [
  { label: '男性', value: 'male' },
  { label: '女性', value: 'female' },
  { label: '無回答', value: 'other' },
]

export const mode = [
  { label: '四人麻雀', value: 1 },
  { label: '三人麻雀', value: 2 },
]

export const rate = [
  { label: '点1（10G／千点）', value: 1 },
  { label: '点2（20G／千点）', value: 2 },
  { label: '点3（30G／千点）', value: 3 },
  { label: '点5（50G／千点）', value: 5 },
  { label: '点10（100G／千点）', value: 10 },
  { label: '点20（200G／千点）', value: 20 },
  { label: '点50（500G／千点）', value: 50 },
  { label: '点100（1000G／千点）', value: 100 },
  { label: '点200（2000G／千点）', value: 200 },
]

export const yakuman = [
  { label: '国士無双', value: 1 },
  { label: '国士無双（13面）', value: 2 },
  { label: '四暗刻', value: 3 },
  { label: '四暗刻（単騎）', value: 4 },
  { label: '大三元', value: 5 },
  { label: '大四喜', value: 6 },
  { label: '小四喜', value: 7 },
  { label: '字一色', value: 8 },
  { label: '緑一色', value: 9 },
  { label: '緑一色（發無）', value: 10 },
  { label: '清老頭', value: 11 },
  { label: '九蓮宝燈', value: 12 },
  { label: '九蓮宝燈（純正）', value: 13 },
  { label: '四槓子', value: 14 },
  { label: '天和', value: 15 },
  { label: '地和', value: 16 },
  { label: 'その他', value: 17 },
]
