import axios, { InternalAxiosRequestConfig } from 'axios'
import store from './store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT + '/api/v1/',
})

api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  if (store.state.token) config.headers.set('Authorization', `Bearer ${store.state.token}`)
  if (store.state.viewer) config.headers.set('X-Viewer', store.state.viewer.id)
  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    store.commit('errorResponse', error)
    if (error.response.status === 401) {
      store.commit('token', null)
    }
  },
)

export default api
