import { GameField } from './gameField'

export class Member {
  public static createFromJson(json: any): Member {
    const win = json.win ?? 0
    const los = json.los ?? 0
    const eve = json.eve ?? 0
    const cnt = win + los + eve
    return new Member(
      json.id,
      json.name,
      json.email,
      json.userId,
      json.picture,
      json.state,
      null,
      win,
      los,
      eve,
      cnt,
      json.yakuman ?? 0,
      json.yakitori ?? 0,
      json.total,
      (json.gameFields ?? []).map((it: any) => GameField.createFromJson(it)),
      new Date(json.createdAt),
    )
  }

  constructor(
    public id: string,
    public name: string,
    public email: string | null,
    public userId: string | null,
    public picture: string | null,
    public state: number,
    public ranking: number | null,
    public win: number,
    public los: number,
    public eve: number,
    public cnt: number,
    public yakuman: number,
    public yakitori: number,
    public total: number,
    public gameFields: GameField[],
    public createdAt: Date,
  ) {}
}

export type MemberInput = {
  name: string
  email: string | null
  userId: string | null
  picture: string | null
}
